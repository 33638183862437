import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Newsletter, NewsletterService } from './';
import { CoreMessageService, CoreModalService, CoreStorageService, CoreUtilsService } from '../core'
import { Subscription } from "rxjs";
import { User, UserService } from "../user";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Organization } from 'app/organization';


@Component({
  selector: 'newsletter-edit',
  templateUrl: './newsletter-edit.component.html',
  styles: [`
    @media only screen and (max-width: 600px) { /* Modifica 600px con la larghezza desiderata per considerare come mobile */
      .button-mobile {
        display: flex;
        flex-direction: column;
      }
    }
  `]
})

export class NewsletterEditComponent implements OnInit {
  @Input() mode = ''; // 'inline' when used as child view
  @Input() paramsIn: any // params inherited by parent in inline view
  @Input() objIn: any = {} // obj inherited by parent in inline view

  objForm: FormGroup;

  subscription: Subscription = new Subscription(); // Contiene le subscribtions che verranno eliminate al destroy del componente
  isSmallScreen: boolean = false;
  endpointStatic: string;

  previewUser?: User = null;
  obj: Newsletter;
  params: any = {};
  query: any = {};
  new = false; // true for creation of new entity, false instead
  saveLoading = false;
  saved = false;
  data: {}; // data retrieved by core storage

  tabs = ['newsletter.recipients', 'newsletter.contents']; //list of tabs
  tab = 0; // tab index

  countUsersInProgress: boolean = false;
  sendAtDate?: Date = null;
  sendAtTime?: string = null;
  userCount: number = 0;
  testInProgress: boolean = false;
  planInProgress: boolean = false;
  sendInProgress: boolean = false;
  usersCount: number = 0 // the number of users touched by this newsletter
  translations: string[];
  fieldsError: {} = {};

  minDate = new Date();
  identity: any; // data from core storage
  organizations = [];  // List of available organizations
  organizationSettings: IDropdownSettings = { // Dropdown configs
    singleSelection: false,
    enableCheckAll: false,
    idField: 'id',
    textField: 'name',
    selectAllText: this.translate.instant('COMMON.selectAll'),
    unSelectAllText: this.translate.instant('COMMON.usSelectAll'),
    itemsShowLimit: 5,
    allowSearchFilter: true
  };
  selectedOrganizations: Organization[] = []

  constructor(
    private newsletterService: NewsletterService,
    private router: Router,
    private route: ActivatedRoute,
    private message: CoreMessageService,
    private storage: CoreStorageService,
    private utils: CoreUtilsService,
    private modalService: CoreModalService,
    private translate: TranslateService,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    this.subscription.add(
      this.message.get('layout.changed').subscribe((m: any) => {
        this.isSmallScreen = m.mode === 'mobile';
      })
    );

    this.identity = this.storage.retrieve('identity') || {};

    this.endpointStatic = this.utils.getStaticEndpoint();
  }

  ngOnInit(): void {
    this.initForm()

    this.route.params.subscribe(params => {

      if (params.id !== '0') {

        this.params = params;
        this.search();

      }

    });

    // create a new entity with default data
    this.obj = new Newsletter().applyModel(Object.assign({
      year: 2018,
      area_id: null,
      brand_id: null,
      degree_id: null,
      agency_id: null
      //new model
    }, this.objIn));

    // retrieve data from core storage
    this.data = this.storage.retrieve("data");
    this.organizations = this.data['organization'] || []

    this.subscription.add(
      this.translate
        .stream([
          'newsletter.createdMsg',
          'newsletter.modifiedMsg',
          'newsletter.testedMsg',
          'newsletter.sentMsg',
          'newsletter.modalTitle',
          'newsletter.testConfirm',
          'newsletter.planConfirm',
          'newsletter.sendConfirm',
          'COMMON.errorMsg'
        ])
        .subscribe(
          updatedTranslations => (this.translations = updatedTranslations)
        )
    );

  }

  isArrayValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // Check if the form group is defined
      if (!this.objForm) {
        return { 'formGroupUndefined': true };
      }

      // Check if the control is defined
      const formControl = this.objForm.get(controlName);
      if (!formControl) {
        return { [`${controlName}Undefined`]: true };
      }

      // Perform additional validation logic based on your requirements
      // For example, check if the control value is an array with at least one element
      const controlValue = formControl.value;
      if (!Array.isArray(controlValue) || controlValue.length < 0) {
        return { 'noOneSelected': true };
      }

      return null;  // Validation passed
    };
  }

  initForm(): void {
    this.objForm = this.fb.group({
      name: [null, Validators.required],
      model: [null, Validators.required],
      // statuses: [null, this.isArrayValidator('statuses')],
      roles: [null, this.isArrayValidator('roles')],
      organization_ids: [null, this.isArrayValidator('organization_ids')],
      subject: [],
      body: [],
      cta_title: [],
      cta_url: [],
      main_color: [],
      testEmail: [],
      testOEM: [],
      send_at: [],
      send_at_time: [],
    });
  }

  // search entity
  search(): void {
    if (this.params['id'] && this.params['id'] != '0') {
      if (this.tabs.indexOf('newsletter.plan') === -1) {
        this.tabs.push('newsletter.plan');
      }

      this.newsletterService
        .get(this.params['id'])
        .then((res: any)=> {
          this.obj = res.models[0];
          this.obj.main_color = this.obj.main_color || '#115c87';
          const date = moment(this.obj.send_at);

          if (this.objForm) {
            this.objForm.setValue({
              name: this.obj.name,
              model: this.obj.model,
              // statuses: this.obj.cluster?.statuses || [],
              roles: this.obj.cluster?.roles || [],
              organization_ids: this.obj.cluster?.organization_ids ||[],
              subject: this.obj.subject,
              body: this.obj.body,
              cta_title: this.obj.cta_title,
              cta_url: this.obj.cta_url,
              main_color: this.obj.main_color,
              testEmail: null,
              testOEM: 1,
              send_at: this.obj.send_at ? {day: date.date(), month: date.month()+1, year: date.year()} : null,
              send_at_time:  this.obj.send_at ? {hour: date.hour(), minute: date.minute()} : null,
            });
          }

          if (
            this.obj.cluster.organization_ids && 
            Array.isArray(this.obj.cluster.organization_ids) &&
            this.obj.cluster.organization_ids.length
          ) {
            this.selectedOrganizations = this.organizations.filter(
              org => this.obj.cluster.organization_ids.indexOf(org.id.toString()) > -1
            )
          }

          this.sendAtDate = date.toDate();
          this.sendAtTime = date.format('HH:mm');
          this.countUsersIncludedInNewsletter();
        });
    } else {
      this.new = true;
    }
  }

  private buildQueryStrings = (params) => {
    const combinations = [];
    const roles = !params || !params.roles || params.roles.length === 0 ? [null] : params.roles;
    const statuses = !params || !params.statuses || params.statuses.length === 0 ? [null] : params.statuses;
    const organization_ids = !params || !params.organization_ids || params.organization_ids.length === 0 ? [null] : params.organization_ids;
  
    for (const role of roles) {
      for (const status of statuses) {
        for (const organization_id of organization_ids) {
          combinations.push({ role, status, organization_id });
        }
      }
    }
    return combinations;
  }

  /**
   * 
   */
  countUsersIncludedInNewsletter() {
    this.countUsersInProgress = true;
    const queries = this.buildQueryStrings({
      roles: this.objForm.get('roles').value || [],
      statuses: ['ACTIVE'],
      organization_ids: this.objForm.get('organization_ids').value || [],
    })

    this.usersCount = 0
    queries.map(
      query => {
        query['limit'] = 1;
        this.userService.get(
          false,
          {},
          query
        ).then(
          res => {
            this.usersCount += res['pagination']['total']
            this.countUsersInProgress = false;
          }
        ).catch(
          err => {
            this.countUsersInProgress = false;
          }
        );
      }
    )
    //   res => {
    //     const pagination = res['pagination'] || {};
    //     this.userCount = pagination['total'] || 0;

    //     if (!!res['models'] && Array.isArray(res['models'])) {
    //       this.previewUser = res['models'][0];
    //     }

    //     this.countUsersInProgress = false;
    //   }

  }

  setRolesValue(roleId: any): void {
    const rolesArray = this.objForm.get('roles');
    // se è null inizializzo ad empty array
    rolesArray.value === null ? rolesArray.setValue([]) : null;
    // Verifica se il ruolo è già presente
    const existingRoleIndex = rolesArray.value.findIndex(value => value === roleId);
    if (existingRoleIndex !== -1) {
      // Rimuovi il ruolo se è già presente
      rolesArray.value.splice(existingRoleIndex, 1)
    } else {
      // Inserisci il ruolo se non è già presente
      rolesArray.value.push(roleId);
    }

    rolesArray.value.length === 0 ? rolesArray.setValue(null) : null;
    this.countUsersIncludedInNewsletter();

  }
  setStatusesValue(statusId: any): void {
    const statusArray = this.objForm.get('statuses');
    // se è null inizializzo ad empty array
    statusArray.value === null ? statusArray.setValue([]) : null;
    // Verifica se il ruolo è già presente
    const existingStatusIndex = statusArray.value.findIndex(value => value === statusId);
    if (existingStatusIndex !== -1) {
      // Rimuovi il ruolo se è già presente
      statusArray.value.splice(existingStatusIndex, 1)
    } else {
      // Inserisci il ruolo se non è già presente
      statusArray.value.push(statusId);
    }

    statusArray.value.length === 0 ? statusArray.setValue(null) : null;
    this.countUsersIncludedInNewsletter();
  }

  setDate($event) {
    const sendAt = moment($event.value);

    this.obj.send_at = sendAt.toISOString();
    this.setTime(this.sendAtTime);
  }

  setTime(timeStr?: string) {
    if (!timeStr) {
      return;
    }
    this.sendAtTime = timeStr;

    const time = timeStr.split(':');

    this.obj.send_at = moment(this.obj.send_at)
      .hour(parseInt(time[0]))
      .minute(parseInt(time[1]))
      .toISOString();
  }

  launchTest(id: string, email: string, oem_id: number) {
    this.modalService
      .confirm({
        title: ' ',
        subtitle: this.translations['newsletter.testConfirm'],
        width: '600px'
      })
      .then(res => {
        if (true !== res) {
          return;
        }
        this.testInProgress = true;
        this.message.change('app.loading', true);
        this.newsletterService
          .test(id, email, oem_id)
          .then(res => {
            this.obj.tested = res.tested;
            this.testInProgress = false;
            this.message.change('app.loading', false);
          })
          .catch(err => {
            this.testInProgress = false;
            this.message.change('app.loading', false);
          });
      });
  }

  savePlan() {

    if (this.obj.tested)


    this.modalService
      .confirm({
        title: ' ',
        subtitle: this.translations['newsletter.planConfirm'],
        width: '600px'
      })
      .then(res => {

        const date = this.objForm.get('send_at').value
        const time = this.objForm.get('send_at_time').value

        const send_at = moment({day: date['day'], month: date['month']-1, year: date['year']})
          .hour(parseInt(time['hour']))
          .minute(parseInt(time['minute']))
          .toISOString();
        if (true !== res) {
          return;
        }
        this.planInProgress = true;
        this.message.change('app.loading', true);
        this.newsletterService
          .plan(this.obj.id, send_at.toString())
          .then(res => {
            this.obj.tested = res.tested;
            this.planInProgress = false;
            this.message.change('app.loading', false);
            this.router.navigate(['/newsletter/list'], {
              replaceUrl: true
            });
          })
          .catch(err => {
            this.planInProgress = false;
            this.message.change('app.loading', false);
          });
      });
  }

  sendEmail() {
    this.modalService
      .confirm({
        title: ' ',
        subtitle: this.translations['newsletter.sendConfirm'],
        width: '600px'
      })
      .then(res => {
        if (true !== res) {
          return;
        }
        this.sendInProgress = true;
        this.message.change('app.loading', true);
        this.newsletterService
          .send(this.obj.id)
          .then(res => {
            this.obj.sent_at = res.sent_at;
            this.sendInProgress = false;
            this.router.navigate(['/newsletter/list'], {
              replaceUrl: true
            });
            this.message.change('app.loading', false);
          })
          .catch(err => {
            this.sendInProgress = false;
            this.message.change('app.loading', false);
          });
      });
  }

  save(): void {
    this.message.change('app.loading', true);
    this.saveLoading = true;
    const payload = { ...this.objForm.value };

    // TODO: QUESTO E' UN PROBLEMA !!! MA IN QUESTO MOMENTO E' L'UNICA SOLUZIONE
    payload.type = "GENERIC";
    payload.cluster = {
      roles: payload.roles,
      statuses: ['ACTIVE'],
      organization_ids: payload.organization_ids
    };
    // QUESTA E' UNA FORZATURA, DOVUTA A UNA IMPLEMENTAZIONE ERRATA DIVERSA DA QUANTO E' PRESENTE SU MALCOLM

    // patch (UPDATE)
    if (this.obj.id) {
      this.newsletterService
        .patch(this.obj.id, this.utils.removeEmptyStrings(payload))
        .then(
          (res: any) => {
            setTimeout(() => {
              this.message.change('app.loading', false);
              this.saveLoading = false;
              this.saved = true;
              this.obj.tested = false;
              // trig event in inline mode to notify parents
              if (this.mode == 'inline') {
                res.models[0].loadings['new'] = true;
                this.message.change('newsletter.changed', res.models[0]);
              } else {
                setTimeout(() => {
                  this.saved = false;
                }, 2000);
              }
            }, 1000);
          },
          err => {
            // this.logger.warn(err);
            this.saveLoading = false;
            this.message.change('app.loading', false);
            this.fieldsError =
              !!err && !!err.error && !!err.error.details
                ? err.error.details
                : {};
          }
        );
    }
    // post (CREATE)
    else {
      this.newsletterService
        .post(this.utils.removeEmptyStrings(payload))
        .then(
          (res: any) => {
            setTimeout(() => {
              this.tab = 0;
              this.saveLoading = false;
              this.message.change('app.loading', false);
              this.obj.tested = false;
              this.saved = true;
              setTimeout(() => {
                if (this.mode != 'inline') {
                  this.saved = false;
                }
              }, 2000);
              // broadcast event to parent if it's in inline mode
              if (this.mode == 'inline') {
                res.models[0].loadings['new'] = true;
                this.message.change('newsletter.new', res.models[0]);
              }
              // else move to path if is not in inline mode
              else {
                this.router.navigate(['/newsletter/edit/', res.models[0].id], {
                  replaceUrl: true
                });
              }
            }, 1000);
          },
          err => {
            // this.logger.warn(err);
            this.saveLoading = false;
            this.message.change('app.loading', false);
            this.fieldsError =
              !!err && !!err.error && !!err.error.details
                ? err.error.details
                : {};
          }
        );
    }
  }

  /**
   * 
   * @param item 
   */
  onItemSelect = (item) => {
    const orgArray = this.objForm.get('organization_ids');
    // se è null inizializzo ad empty array
    orgArray.value === null ? orgArray.setValue([]) : null;
    orgArray.value.push(item['id'].toString());
    this.countUsersIncludedInNewsletter();
  }

  /**
   * 
   * @param itens 
   */
  onDeSelect = (item) => {
    const orgArray = this.objForm.get('organization_ids');
    const existingorgIndex = orgArray.value.findIndex(value => value === item['id'].toString());
    if (existingorgIndex !== -1) {
      // Rimuovi il ruolo se è già presente
      orgArray.value.splice(existingorgIndex, 1)
    }
    this.countUsersIncludedInNewsletter();
  }

}
